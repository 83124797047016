(function($) {
var Sage = {
	'common': {
		init: function() {},
		finalize: function() {
			// Recalculate Footer Height
			jQuery('body').css('padding-bottom', jQuery('footer').height());
			jQuery(window).on('resize', function(){
				jQuery('body').css('padding-bottom', jQuery('footer').height());
			});

			// Mobile Menu Button
			jQuery('.menu-mobile').click(function(){
				jQuery(this).toggleClass('active');
				jQuery(this).parent().toggleClass('active');
			});
			jQuery('.menu-item-has-children').click(function(){
				jQuery('.sub-menu',this).toggleClass('active');
			});

			// Video Player
			jQuery('.video-icon').click(function(){
				jQuery(this).prev().fadeOut();
				jQuery(this).fadeOut();
			});

			// Slick slider
			jQuery('.quote-content').slick({
				autoplay: true
			});
			jQuery('.header-homepage').slick({
				dots: true,
				arrows: false,
				fade: true,
				autoplay: true,
				autoplaySpeed: 6000,
				pauseOnFocus: false,
				pauseOnHover: false
			});

			// Isotope
			jQuery('.isotope').isotope();
			var $grid = jQuery('.isotope').isotope();
			jQuery('.blog-filters').on( 'click', 'button', function() {
				var filterValue = jQuery(this).attr('data-filter');
				$grid.isotope({ filter: filterValue });
			});

			// Cookie Alert
			if(Cookies.get('cookie-alert') === '1'){
				jQuery('.cookie-alert').hide();
			}

			jQuery('.cookie-alert .remodal-close').click(function(){
				Cookies.set('cookie-alert','1');
				jQuery('.cookie-alert').fadeOut();
			});
			
			// Home
			function checkCookies(){
				if(Cookies.get('Question-1') === '1' && Cookies.get('Question-2') === '1' && Cookies.get('Question-3') === '1'){
					jQuery('.result-false').fadeOut();
					jQuery('.result-true').fadeIn();

					if (Cookies.get('Answer-1') === 'A' && Cookies.get('Answer-2') === 'A' && Cookies.get('Answer-3') === 'A'){
						jQuery('.team-user').fadeOut();
						jQuery('.herma-de-wit').fadeIn();
						jQuery('.fred-jonkman').fadeIn();
						jQuery('.jos-scholer').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'A' && Cookies.get('Answer-2') === 'A' && Cookies.get('Answer-3') === 'B'){
						jQuery('.team-user').fadeOut();
						jQuery('.herma-de-wit').fadeIn();
						jQuery('.fred-jonkman').fadeIn();
						jQuery('.jos-scholer').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'A' && Cookies.get('Answer-2') === 'B' && Cookies.get('Answer-3') === 'B'){
						jQuery('.team-user').fadeOut();
						jQuery('.herma-de-wit').fadeIn();
						jQuery('.fred-jonkman').fadeIn();
						jQuery('.jos-scholer').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'B' && Cookies.get('Answer-2') === 'A' && Cookies.get('Answer-3') === 'A'){
						jQuery('.team-user').fadeOut();
						jQuery('.aggie-spijkers').fadeIn();
						jQuery('.jacqueline-siderius').fadeIn();
						jQuery('.mathijs-wubbels').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'B' && Cookies.get('Answer-2') === 'B' && Cookies.get('Answer-3') === 'A'){
						jQuery('.team-user').fadeOut();
						jQuery('.aggie-spijkers').fadeIn();
						jQuery('.jacqueline-siderius').fadeIn();
						jQuery('.mathijs-wubbels').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'B' && Cookies.get('Answer-2') === 'B' && Cookies.get('Answer-3') === 'B'){
						jQuery('.team-user').fadeOut();
						jQuery('.aggie-spijkers').fadeIn();
						jQuery('.jacqueline-siderius').fadeIn();
						jQuery('.dian-huyssen-van-kattendijke').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'B' && Cookies.get('Answer-2') === 'A' && Cookies.get('Answer-3') === 'B'){
						jQuery('.team-user').fadeOut();
						jQuery('.aggie-spijkers').fadeIn();
						jQuery('.mathijs-wubbels').fadeIn();
						jQuery('.dian-huyssen-van-kattendijke').fadeIn();
					}

					else if (Cookies.get('Answer-1') === 'A' && Cookies.get('Answer-2') === 'B' && Cookies.get('Answer-3') === 'A'){
						jQuery('.team-user').fadeOut();
						jQuery('.herma-de-wit').fadeIn();
						jQuery('.fred-jonkman').fadeIn();
						jQuery('.jos-scholer').fadeIn();
					}

					else {
						jQuery('.team-user').fadeOut();
					}
				}
			}

			jQuery('.home-form-1 .button').click(function(){
				jQuery('.home-form-1 .button').removeClass('active');
				jQuery(this).addClass('active');
				if(jQuery(this).add('.active')){
					Cookies.set('Question-1', '1');
					Cookies.set('Answer-1', jQuery(this).data('id'));
				}
				checkCookies();
			});

			jQuery('.home-form-2 .button').click(function(){
				jQuery('.home-form-2 .button').removeClass('active');
				jQuery(this).addClass('active');
				if(jQuery(this).add('.active')){
					Cookies.set('Question-2', '1');
					Cookies.set('Answer-2', jQuery(this).data('id'));
				}
				checkCookies();
			});

			jQuery('.home-form-3 .button').click(function(){
				jQuery('.home-form-3 .button').removeClass('active');
				jQuery(this).addClass('active');
				if(jQuery(this).add('.active')){
					Cookies.set('Question-3', '1');
					Cookies.set('Answer-3', jQuery(this).data('id'));
				}
				checkCookies();
			});

			checkCookies();

			// Accordion
			jQuery('.accordion-group .accordion-content').hide();
			jQuery('.accordion-button').click(function(){
				if(jQuery(this).hasClass('active')){
					jQuery(this).next().slideUp();
					jQuery(this).removeClass('active');
				} else {
					jQuery('.accordion-button').removeClass('active');
					jQuery('.accordion-content').slideUp();
					jQuery(this).addClass('active');
					jQuery(this).next().slideDown();
				}
			});
		}
	},
	'home': {
		init: function() {},
		finalize: function() {}
	},
	// About us page, note the change from about-us to about_us.
	'about_us': {
		init: function() {}
	}
};

// ----------------------------------------------------------------------------
var UTIL = {
fire: function(func, funcname, args) {
	var fire;
	var namespace = Sage;
	funcname = (funcname === undefined) ? 'init' : funcname;
	fire = func !== '';
	fire = fire && namespace[func];
	fire = fire && typeof namespace[func][funcname] === 'function';

	if (fire) {
		namespace[func][funcname](args);
	}
},
loadEvents: function() {
	UTIL.fire('common');
	$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	});
	UTIL.fire('common', 'finalize');
	}
};
$(document).ready(UTIL.loadEvents);
})(jQuery);